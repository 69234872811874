import { NgModule } from "@angular/core";
import { OrcidToUrl } from "./orcid-to-url.pipe";


@NgModule({
	declarations: [OrcidToUrl],
	imports: [],
	exports: [OrcidToUrl]
})
export class SharedUtilsModule {

}